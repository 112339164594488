<template>
  <div>
    <div class="container small">
      <h1 class="page-headline">{{ product.productName }}</h1>
      <progressIndicator :step="product.step" :steps="product.steps" />
    </div>
    <template v-if="product.step == 1">
      <div class="container small">
        <div class="box">
          <FormStep
            :fields="product.fields"
            :product="product"
            :step="product.step"
            v-on:submitForm="submitStep1"
            v-on:formFunction="formFunction"
          />
        </div>
        <!-- Infobesked -->
        <message
          :message="_bbr_info"
          type="info"
          v-on:formFunction="formFunction"
        />
        <!-- Fejlbesked -->
        <message
          v-show="error.step1"
          :message="error.step1"
          type="warning"
          v-on:formFunction="formFunction"
        />
      </div>
    </template>
    <template v-else-if="product.step == 2">
      <div class="container small">
        <div class="box">
          <FormStep
            :fields="product.fields"
            :product="product"
            :step="product.step"
            v-on:submitForm="submitStep2"
            v-on:formFunction="formFunction"
          />
        </div>
        <!-- Infobesked -->
        <message
          v-show="info.step2"
          :message="info.step2"
          type="info"
          v-on:formFunction="formFunction"
        />
        <!-- Fejlbesked -->
        <message
          v-show="error.step2"
          :message="error.step2"
          type="warning"
          v-on:formFunction="formFunction"
        />
      </div>
    </template>
    <template v-else-if="product.step == 3">
      <form id="step3" v-on:submit.prevent="submitStep3">
        <div class="container small">
          <div class="box">
            <!-- Felter på trin 3 -->
            <template v-for="(field, index) in product.fields">
              <FormInput
                v-if="field.step == 3"
                :data="field"
                :product="product"
                :key="index"
                v-on:formFunction="formFunction"
              />
            </template>
          </div>

          <!-- Fejlbesked -->
          <message
            v-show="error.price"
            :message="error.price"
            type="warning"
            v-on:formFunction="formFunction"
          />
        </div>

        <!-- Pakkekomponent -->
        <packages
          altLinkText="Læs mere om vores sommerhusforsikring"
          :packages="product.packages"
          :packageLink="product.packageLink"
          :requirementsMet="allRequirementsMet"
          v-on:packageChoice="choosePackage"
        />

        <!-- Extra packages -->
        <coverages
          v-model="_coverages"
          :requirementsMet="allRequirementsMet"
          v-on:packageChoice="choosePackage"
        />

        <div class="container">
          <!-- Infobesked -->
          <message
            v-show="info.step3"
            :message="info.step3"
            type="info"
            v-on:formFunction="formFunction"
          />
        </div>
      </form>
    </template>

    <!-- Buttons on bottom -->
    <div class="container small">
      <ProgressButtons
        :step="product.step"
        :steps="product.steps"
        :existingPolicy="existingPolicy"
        @changeStep="changeStep"
      />
    </div>

    <!-- Product footer -->
    <product-footer
      :productName="product.productName"
      :productId="product.productId"
      :productLink="product.productLink"
      v-on:contactFromFooter="messageBox"
    >
      Vi dækker skader på dit sommerhus som følge af brand, storm, vand, frost, hærværk og tyveri, ligesom vi også dækker dit faste indbo, hvis der skulle ske et indbrud.
    </product-footer>
  </div>
</template>
<script>
import P23 from "@/assets/json/P23.json";
import FormInput from "@/components/FormInput.vue";
import progressIndicator from "@/components/progress-indicator.vue";
import ProgressButtons from "@/components/ProgressButtons.vue";
import message from "@/components/message.vue";
import productFooter from "@/components/product-footer.vue";
import packages from "@/components/packages.vue";
import FormStep from "@/components/FormStep.vue";
import commonProduct from "@/mixins/commonProduct.js";
import validateData from "@/mixins/validateData.js";
import debounce from "lodash/debounce";
import coverages from "@/components/coverages.vue";

export default {
  name: "Husforsikring",
  components: {
    FormInput,
    progressIndicator,
    ProgressButtons,
    message,
    productFooter,
    packages,
    FormStep,
    coverages,
  },
  props: {
    policy: {
      type: Object,
      default: null,
    },
  },
  mixins: [commonProduct, validateData],
  data() {
    return {
      productId: "P23",
      product: this.copy(P23),
      temporaryProduct: this.copy(this.$store.state.temporaryProduct),
    };
  },
  created() {
    /*
     * If you edit and existing offer copy the policy here, or if you have temporary object
     */
    if (this.policy) {
      // Delete temporary product
      this.$store.commit("deleteTemporaryProduct");

      this.product = this.copy(this.policy.value);
      //this.existingPolicy = true;
      //this.existingPolicyKey = this.policy.key;
    } else if (
      !this.$route.query.input &&
      this.temporaryProduct &&
      this.temporaryProduct.productId == this.productId
    ) {
      this.product = this.copy(this.temporaryProduct);
    }
  },
  mounted() {
    // Function if input has come from website
    if (this.$route.query.input) {
      // Get query and delete input
      let query = this.copy(this.$route.query);
      query.input = undefined;

      // Push on without input
      this.$router
        .push({ name: "Indboforsikring", query: query })
        .catch(() => {});
    }
  },
  computed: {
    /*
     * Check requirements
     */
    allRequirementsMet() {
      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(this.product.fields)) {
        if (value.required) {
          if (value.value === null || value.error) {
            this.cerror(value);
            return false;
          }
        }
      }
      return true;
    },
    _bbr_info() {
      return {
          headline: "Info om BBR og besigtigelse",
          text: "Vi bruger ovenstående oplysninger om dit hus til at beregne den rigtige pris på din husforsikring. Hovedparten af disse oplysninger henter vi fra BBR. Det er vigtigt, at du tjekker, at de ovenstående oplysninger er korrekte, da der kan være foretaget ændringer på ejendommen, som ikke er blevet indberettet til BBR. Ellers risikerer du at få vist en pris, der ikke er retvisende.\n\nVores bygningstaksator skal herudover kunne godkende huset i overensstemmelse med de oplysninger, du har angivet ovenfor. Dette kan medføre forbehold, der kan have indflydelse på prisen eller din mulighed for at tegne forsikringen.",
          button: null
        };
    },
    _coverages() {
      const filter = (
        ["B", "PT"].includes(this.product.fields.wallType.value) ||
        ["GT", "SP"].includes(this.product.fields.roofType.value) ||
        this.product.fields.pool.value ||
        this.product.fields.constructionYear.value < 1975
      );

      let r = filter ? [] : [
        this.product.coverages.fungus,
        this.product.coverages.advice,
      ];

      r.push(this.product.coverages.leasing);
      r.push(this.product.coverages.damage);
      r.push(this.product.coverages.electronics);

      if (filter) {
        this.product.coverages.fungus.chosen = false;
        this.product.coverages.advice.chosen = false;

        this.product.fields.excessText.description = 'Vær opmærksom på, at selvrisikoen på udlejning, pludselig skade, elektronik, anden pludselig skade samt tab af vand, olie og gas kan afvige fra den selvrisiko, du vælger her. Du kan læse mere om selvrisikoen på de nævnte produkter ved at klikke på produkterne i pakkeløsningerne nedenfor.';
      } else {
        this.product.fields.excessText.description = 'Vær opmærksom på, at selvrisikoen på svamp og insekt, råd, udlejning, pludselig skade, elektronik, anden pludselig skade samt tab af vand, olie og gas kan afvige fra den selvrisiko, du vælger her. Du kan læse mere om selvrisikoen på de nævnte produkter ved at klikke på produkterne i pakkeløsningerne nedenfor.';
      }

      return r;
    }
  },
  watch: {
    "product.fields": {
      handler: function () {
        this.calculateEveryPrice();
      },
      deep: true,
    },
    "product.coverages": {
      handler: function () {
        if (this.product.coverages.fungus.chosen) {
          this.product.coverages.advice.blocked = false;
        } else {
          this.product.coverages.advice.blocked = true;
          this.product.coverages.advice.chosen = false;
        }

        this.calculateEveryPrice();
      },
      deep: true,
    },
    "product.fields.customerAddress.selected": {
      handler(n) {
        if (n === null) {
          // Clear dependent fields
          this.product.fields.constructionYear.value = null;
          this.product.fields.totalLivingSpace.value = null;
          this.product.fields.secondArea.value = null;
          this.product.fields.bathroom.value = null;
          this.product.fields.roofType.value = null;
          this.product.fields.wallType.value = null;
          this.product.fields.primaryHeating.value = null;
          this.product.fields.buildingType.value = null;
        }
      },
      deep: true
    }
  },
  methods: {
    search() {
      this.clog("Search");
    },
    /*
     * When you select dawa option
     */
    async setAddress(option) {
      this.clog("set address", option);

      this.product.fields.customerAddress.error = null;

      this.product.fields.fullAddress.value = option.adressebetegnelse;
      this.product.fields.street.value = option.adgangsadresse.vejstykke.adresseringsnavn;
      this.product.fields.streetNumber.value = option.adgangsadresse.husnr;
      this.product.fields.postalCode.value = option.adgangsadresse.postnummer.nr;
      this.product.fields.zipCode.value = option.adgangsadresse.postnummer.nr;
      this.product.fields.postalCity.value = option.adgangsadresse.postnummer.navn;
      this.product.fields.entrance.value = option.dør;

      this.product.fields.address.value = {
        Street: option.adgangsadresse.vejstykke.adresseringsnavn,
        StreetNumber: option.adgangsadresse.husnr,
        ZipCode: option.adgangsadresse.postnummer.nr,
      };

      // Get other values
      try {
        const { buildingInformation } = await this.$api.building.informationCottage.read(option.adressebetegnelse);
        this.product.fields.floors.value = buildingInformation.floors;

        this.product.fields.constructionYear.value = buildingInformation.constructionYear;

        this.product.fields.totalLivingSpace.value = buildingInformation.totalLivingSpace;
        this.product.fields.secondArea.value = buildingInformation.secondArea.toString();
        //this.product.fields.basementArea.value = "0";

        this.product.fields.bathroom.value = buildingInformation.bathroom;
        this.product.fields.roofType.value = buildingInformation.roofType;
        this.product.fields.wallType.value = buildingInformation.wallType;
        this.product.fields.landReferenceNumber.value = buildingInformation.landReferenceNumber;
        this.product.fields.buildingType.value = buildingInformation.buildingType;
      } catch (error) {
        // Nothing
        this.clog('catch here');
        this.resetAddress();
        this.product.fields.customerAddress.error = 'Ud fra den indtastede adresse kunne vi ikke finde informationer om huset. Udfyld kontaktformularen og vores kundeservice kontakter dig for at give et tilbud på dine forsikringer.';
      }
    },
    /*
     * Reset address fields
     */
    resetAddress() {
      this.product.fields.customerAddress.error = null;
      this.product.fields.customerAddress.selected = null;
      this.product.fields.fullAddress.value = null;
      this.product.fields.street.value = null;
      this.product.fields.streetNumber.value = null;
      this.product.fields.postalCode.value = null;
      this.product.fields.zipCode.value = null;
      this.product.fields.postalCity.value = null;
      this.product.fields.floors.value = null;
      this.product.fields.entrance.value = null;
    },
    /*
     * Event when you choose a package
     */
    calculateEveryPrice: debounce(function () {
      let self = this;

      // Update temporary product if product is not existing
      if (!self.existingPolicy) {
        self.$store.commit("temporaryProduct", self.product);
      }

      // If all requirements are not met, break from function
      if (!self.allRequirementsMet) {
        this.cerror("Not all requirements met");
        return;
      }

      // Remove error
      self.error.price = null;

      // Get submit data without coverages
      let submitData = self.parseSubmitData(self.product);

      // Console log if in dev, test or beta environment
      if (
        process.env.NODE_ENV == "development" ||
        process.env.NODE_ENV == "test" ||
        process.env.NODE_ENV == "beta"
      ) {
        console.log(submitData);
      }

      // Create encrypted data
      let encryptedData = JSON.stringify(
        self.encrypt(JSON.stringify(submitData))
      );

      // Create api string
      let apiString = `/api/price`;

      self
        .axios({
          method: "post",
          url: apiString,
          headers: {
            "Content-Type": "application/json",
            "x-ws-key": process.env.VUE_APP_KEY,
            Authorization: `Bearer ${self.$store.state.token}`,
          },
          data: encryptedData,
        })
        .then((response) => {
          // If weird error shows, run function again
          if (response.data.objects && response.data.objects.length < 3) {
            self.calculateEveryPrice();
            return;
          }

          self.insertPrice(response.data);
        })
        .then(() => {
          // Show popup
          self.$store.commit("showPopup");

          // Update temporary product if product is not existing
          if (!self.existingPolicy) {
            self.$store.commit("temporaryProduct", self.product);
          }

          // If exiting policy, update existing policy
          if (self.existingPolicy) {
            self.updateExistingPolicy(self.product, self.existingPolicyKey);
          }
        })
        .catch(function (err) {
          self.cerror(err);

          // Show error
          self.error.price = {
            headline: "Vi kan desværre ikke beregne en pris online",
            text: "Det er desværre ikke muligt at beregne en pris online. Udfyld kontaktformularen og vores kundeservice kontakter dig for at give et tilbud på dine forsikringer.",
            button: {
              text: "Kontakt mig",
              function: "messageBox",
            },
          };

          // Remove loading for packages
          // eslint-disable-next-line no-unused-vars
          for (const [key, value] of Object.entries(self.product.packages)) {
            value.loading = null;
          }
        });
    }, 250),
    /*
     * Submit step 1 in form
     */
    async submitStep1() {
      let self = this;

      //*****************************************
      // CLEANUP
      //*****************************************

      // Reset validation error
      self.validationError = null;

      // Reset error message
      self.error[`step${self.product.step}`] = null;

      //*****************************************
      // VALIDATION
      //*****************************************

      // Residency not set
      /*if (this.product.fields.resident.value === null) {
        this.product.fields.resident.error = this.product.fields.resident.validationMessage;
        return;
      }*/

      //*****************************************
      // MESSAGE BOXES
      //*****************************************

      // Primary heating form == other
      if (this.product.fields.primaryHeating.value === "AN") {
        self.error[`step${self.product.step}`] = {
          headline: "Vi kan desværre ikke beregne en pris online",
          text: "Den valgte opvarmningsform betyder, at vi ikke kan beregne en pris online. Når du udfylder kontaktformularen, kontakter vores kundeservice dig med et tilbud på dine forsikringer.",
          button: {
            text: "Kontakt mig",
            function: "messageBox",
          },
        };

        return;
      }

      // Area > 350, Other area > 100, bathrooms > 6, roof == [strå, tørv], wall == [binding, træ, pudset træ], residency == false
      if (
        parseInt(this.product.fields.totalLivingSpace.value) > 350 ||
        parseInt(this.product.fields.secondArea.value) > 100 ||
        parseInt(this.product.fields.bathroom.value) > 6 ||
        ["SU", "S", "GT", "SP"].includes(this.product.fields.roofType.value)
      ) {
        self.error[`step${self.product.step}`] = {
          headline: "Vi kan desværre ikke beregne en pris online",
          text: "På baggrund af de oplysninger, du har tilføjet om dit sommerhus, kan vi desværre ikke beregne en pris til dig online. Når du udfylder kontaktformularen, kontakter vores kundeservice dig med et tilbud på dine forsikringer.",
          button: {
            text: "Kontakt mig",
            function: "messageBox",
          },
        };

        return;
      }

      //*****************************************
      // GENERIC SUBMIT
      //*****************************************

      // Set step 1 to loading
      self.product.steps[0].loading = true;

      // Validate the data from step 1
      let validationResult = await self.validateData(
        self.product,
        self.product.step
      );

      // Send customer along if valid, otherwise show error
      if (validationResult.isValid) {
        self.product.step += 1;
      } else {
        // Set validation error from response
        self.validationError = validationResult.message;

        // Set error message
        self.error[`step${self.product.step}`] = {
          headline: "Vi kan desværre ikke beregne en pris online",
          text: validationResult.message,
          button: {
            text: "Kontakt mig",
            function: "messageBox",
          },
        };
      }

      // Set loading to false
      self.product.steps[0].loading = false;
    },
    /*
     * Submit step 2 in form
     */
    async submitStep2() {
      let self = this;

      //*****************************************
      // CLEANUP
      //*****************************************

      // Reset validation error
      self.validationError = null;

      // Reset error message
      self.error[`step${self.product.step}`] = null;

      //*****************************************
      // MESSAGE BOXES
      //*****************************************

      // Age < 18
      if (parseInt(this.product.fields.age.value) < 18) {
        self.error[`step${self.product.step}`] = {
          headline: "Vi kan desværre ikke beregne en pris online",
          text: "Ud fra den oplyste alder, kan vi desværre ikke beregne en pris online. Udfyld kontaktformularen og vores kundeservice kontakter dig for at give et tilbud på dine forsikringer.",
          button: {
            text: "Kontakt mig",
            function: "messageBox",
          },
        };

        return;
      }

      // Claims > 5
      if (parseInt(this.product.fields.claimsQuantity.value) > 5) {
        self.error[`step${self.product.step}`] = {
          headline: "Vi kan desværre ikke beregne en pris online",
          text: "Vi kan desværre ikke beregne en pris online på baggrund af de indtastede oplysninger. Udfyld kontaktformularen, og vores kundeservice kontakter dig for at give et tilbud på dine forsikringer.",
          button: {
            text: "Kontakt mig",
            function: "messageBox",
          },
        };

        return;
      }

      //*****************************************
      // GENERIC SUBMIT
      //*****************************************

      // Set step 1 to loading
      self.product.steps[1].loading = true;

      // Validate the data from step 1
      let validationResult = await self.validateData(
        self.product,
        self.product.step
      );

      // Send customer along if valid, otherwise show error
      if (validationResult.isValid) {
        self.product.step += 1;
      } else {
        // Set validation error from response
        self.validationError = validationResult.message;

        // Set error message
        self.error[`step${self.product.step}`] = {
          headline: "Vi kan desværre ikke beregne en pris online",
          text: validationResult.message,
          button: {
            text: "Kontakt mig",
            function: "messageBox",
          },
        };
      }

      // Set step 1 to loading
      self.product.steps[1].loading = false;
    },
    /*
     * Submit step 3 in form
     */
    submitStep3() {
      // Check if policy is exitting or new
      if (this.existingPolicy) {
        this.updateExistingPolicy(this.product, this.existingPolicyKey);
      } else {
        this.$store.commit("addProduct", this.product);
      }

      // Delete temporary product
      this.$store.commit("deleteTemporaryProduct");

      // Route to shopping basket
      this.$router.push({ name: "Kurv" });
    },
    /*
     * Update existing policy
     */
    updateExistingPolicy(product, key) {
      this.$store.commit("updateProduct", {
        value: product,
        key: key,
      });
    },
  },
};
</script>
